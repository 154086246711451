.navbar-overlay ul.navbar-nav li a {
	text-decoration: none;
}

.container-img-about-me {
	width: 360px !important;
	height: 350px !important;
	display: block !important;
	padding-top: 22px !important;
}

.pr-4 {
	padding-right: 2rem;
}

.img-intro {
	filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.4));
}

.img-about-container {
	height: 600px;
}

.img-about-me {
	position: relative;
	top: -150px;
}

.height-horizontal-carousel {
	height: 50vh;
}
@media (min-width: 990px) {
	.img-intro {
		position: relative;
		bottom: -10vh;
	}
}

@media (max-width: 999px) {
	.height-horizontal-carousel {
		height: 58vh;
	}
}

@media (max-width: 480px) {
	// .img-intro {
	// 	max-height: 550px;
	// }

	.img-intro-container {
		max-height: 420px;
	}

	.img-about-container {
		height: 100vw;
	}

	.img-about-me {
		position: relative;
		top: -250px;
	}
}

// @media print {
//     .img-about-me {
//         page-break-inside: avoid;
//     }
// }

// @media all {
//     .img-about-me {
//         max-width: 100%;
//         height: auto;
//     }

//     ::selection {
//         background: #e6b426;
//     }

//     .animacion-clip-path {
//         animation: CLIPPATH 3s;
//     }
// }

// /*! CSS Used keyframes */
// @keyframes CLIPPATH {
//     0% {
//         clip-path: polygon(0 0, 0 0, 35% 100%, 35% 100%);
//     }

//     50% {
//         clip-path: polygon(0 0, 70% 0, 100% 100%, 35% 100%);
//     }

//     100% {
//         clip-path: polygon(70% 0, 70% 0, 100% 100%, 100% 100%);
//     }
// }
